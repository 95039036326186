import axios, { AxiosResponse } from "axios";
import { Genre } from "./genre.model";
import { endpoints } from "./endpoints";

type Create = {
  name: string;
};

export class GenreService {
  static create = async (data: Create): Promise<AxiosResponse<Genre>> => {
    return axios.post(endpoints.create.insert(), {
      name: data.name,
    });
  };

  static retrieveAll = async (): Promise<AxiosResponse<Genre[]>> => {
    return axios.get(endpoints.create.insert());
  };

  static remove = async (genreId: string): Promise<AxiosResponse<void>> => {
    return axios.delete(endpoints.remove.insert({ genreId }));
  };
}
