import { SweetPath } from "sweet-path";

export const endpoints = {
  create: new SweetPath(`/api/v1/admin/games`),
  createBuyLink: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId/buyLinks`),
  updateBuyLink: new SweetPath<"gameId" | "buyLinkId">(`/api/v1/admin/games/:gameId/buyLinks/:buyLinkId`),

  retrieveById: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId`),
  retrieveAll: new SweetPath(`/api/v1/admin/games`),

  update: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId`),
  updateCrosses: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId/crosses`),
  updateImages: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId/image`),
  updateSeoCrossplay: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId/seo/crossplay`),
  updatePublishStatus: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId/publishStatus`),

  updateGenres: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId/genres`),

  remove: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId`),
  removeBuyLink: new SweetPath<"gameId" | "buyLinkId">(`/api/v1/admin/games/:gameId/buyLinks/:buyLinkId`),

  createCrossplayVerification: new SweetPath<"gameId">(`/api/v1/admin/games/:gameId/crossplay-verification`),
  updateCrossplayVerification: new SweetPath<"gameId" | "crossplayVerificationId">(`/api/v1/admin/games/:gameId/crossplay-verification/:crossplayVerificationId`),
  removeCrossplayVerification: new SweetPath<"gameId" | "crossplayVerificationId">(`/api/v1/admin/games/:gameId/crossplay-verification/:crossplayVerificationId`),

  retrieveOnlyWithSortOrder: new SweetPath(`/api/v1/admin/games-with-sort-priority`),
  applySortOrder: new SweetPath<'gameId'>(`/api/v1/admin/games/:gameId/appSpecificConfig/crossplay/sort-priority`),

  updateMediaGallery: new SweetPath<'gameId'>(`/api/v1/admin/games/:gameId/gallery`)
};
