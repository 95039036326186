import axios, { AxiosResponse } from "axios";
import { endpoints } from "./endpoints";
import { Game, GameImage } from "./game.model";
import { BuyLink } from "../shared/buyLink.model";
import { PagReq } from "../shared/pagReq.model";
import { PagRes } from "../shared/pagRes.model";

class GameService {
  create(data: Pick<Game, "name" | "description" | "releaseDate">): Promise<AxiosResponse<Game>> {
    return axios.post(endpoints.create.insert(), {
      name: data.name,
      description: data.description,
      releaseDate: data.releaseDate,
    });
  }

  createBuyLink(id: string, data: Pick<BuyLink, "url" | "store" | "platform">): Promise<AxiosResponse<BuyLink>> {
    return axios.post(endpoints.createBuyLink.insert({ gameId: id }), {
      url: data.url,
      store: data.store,
      platform: data.platform,
    });
  }

  updateBuyLink(id: string, data: BuyLink): Promise<AxiosResponse<BuyLink>> {
    return axios.put(endpoints.updateBuyLink.insert({ gameId: id, buyLinkId: data._id }), data);
  }

  retrieveById(id: string): Promise<AxiosResponse<Game>> {
    return axios.get(endpoints.retrieveById.insert({ gameId: id }));
  }

  retrieveAll(pagReq: PagReq & object): Promise<AxiosResponse<PagRes<Game>>> {
    return axios.get(endpoints.retrieveAll.insert(), { params: pagReq });
  }

  update(id: string, data: Pick<Game, "name" | "description" | "releaseDate">): Promise<AxiosResponse<Game>> {
    return axios.put(endpoints.update.insert({ gameId: id }), {
      name: data.name,
      description: data.description,
      releaseDate: data.releaseDate,
    });
  }

  updateCrosses(id: string, data: Array<string[]>): Promise<AxiosResponse<Game>> {
    return axios.put(endpoints.updateCrosses.insert({ gameId: id }), {
      crosses: data,
    });
  }

  updateImage(id: string, data: Game["image"]): Promise<AxiosResponse<{ fileName: string }>> {
    return axios.put(endpoints.updateImages.insert({ gameId: id }), data);
  }

  updateSeoCrossplay(
    id: string,
    data: Omit<Game["seo"]["crossplay"], "keywords"> & {
      manualKeywords: Game["seo"]["crossplay"]["keywords"]["manual"];
    }
  ): Promise<AxiosResponse<Game>> {
    return axios.put(endpoints.updateSeoCrossplay.insert({ gameId: id }), data);
  }

  updatePublishStatus(id: string, data: Game["publishStatus"]): Promise<AxiosResponse<Game>> {
    return axios.put(endpoints.updatePublishStatus.insert({ gameId: id }), {
      publishStatus: data,
    });
  }

  remove(id: string): Promise<AxiosResponse<void>> {
    return axios.delete(endpoints.remove.insert({ gameId: id }));
  }

  removeBuyLink(gameId: string, buyLinkId: string): Promise<AxiosResponse<void>> {
    return axios.delete(endpoints.removeBuyLink.insert({ gameId, buyLinkId }));
  }

  createCrossplayVerification(gameId: string, data: Pick<Game["crossplayVerification"][number], "url">) {
    return axios.post(endpoints.createCrossplayVerification.insert({ gameId }), { ...data });
  }

  updateCrossplayVerification(
    gameId: string,
    crossplayVerificationId: string,
    data: Pick<Game["crossplayVerification"][number], "url">
  ) {
    return axios.put(endpoints.updateCrossplayVerification.insert({ gameId, crossplayVerificationId }), {
      ...data,
    });
  }

  removeCrossplayVerification(gameId: string, crossplayVerificationId: string) {
    return axios.delete(endpoints.removeCrossplayVerification.insert({ gameId, crossplayVerificationId }));
  }

  retrieveOnlyWithCrossplayStaticSortPriority(): Promise<AxiosResponse<Game[]>> {
    return axios.get(endpoints.retrieveOnlyWithSortOrder.insert());
  }

  applyCrossplayStaticSortPriority(gameId: string, sortPriority: number) {
    return axios.put(endpoints.applySortOrder.insert({ gameId }), {
      sortPriority,
    });
  }

  updateMediaGallery(gameId: string, gallery: Array<Pick<GameImage, "key" | "gradient"> & { _id?: string | void }>) {
    return axios.put(endpoints.updateMediaGallery.insert({ gameId }), {
      mediaGallery: gallery,
    });
  }

  updateGenres(gameId: string, genresIds: string[]): Promise<AxiosResponse<void>> {
    return axios.put(endpoints.updateGenres.insert({ gameId }), {
      genresIds,
    });
  }
}
export const gameService = new GameService();
