import { Badge, Flex } from "@chakra-ui/react";
import { Game } from "../../../../api/games/game.model";
import { GameUrlTabNames } from "../../../GamePage/game-url-tab-names";
import { Link } from "react-router-dom";
import { GameCompletionService } from "../../../../services/game-completion.service";

type Props = {
  game: Game;
};

export const GameCompletionStatuses = (props: Props) => {
  return (
    <Flex columnGap={2} rowGap={2}>
      <Link to={`/games/${props.game._id}/${GameUrlTabNames.BasicInfo}`}>
        <Badge variant={"outline"} px={2} py={1} colorScheme={GameCompletionService.basicInfoIsCompleted(props.game)}>
          Basic Info
        </Badge>
      </Link>
      <Link to={`/games/${props.game._id}/${GameUrlTabNames.SEO}`}>
        <Badge variant={"outline"} px={2} py={1} colorScheme={GameCompletionService.SEOCompleted(props.game)}>
          SEO
        </Badge>
      </Link>
      <Link to={`/games/${props.game._id}/${GameUrlTabNames.BuyLinks}`}>
        <Badge variant={"outline"} px={2} py={1} colorScheme={GameCompletionService.buyLinksCompleted(props.game)}>
          Buy Links
        </Badge>
      </Link>
      <Link to={`/games/${props.game._id}/${GameUrlTabNames.Crosses}`}>
        <Badge variant={"outline"} px={2} py={1} colorScheme={GameCompletionService.crossesCompleted(props.game)}>
          Crosses
        </Badge>
      </Link>
      <Link to={`/games/${props.game._id}/${GameUrlTabNames.Image}`}>
        <Badge variant={"outline"} px={2} py={1} colorScheme={GameCompletionService.imageCompleted(props.game)}>
          Image
        </Badge>
      </Link>
      <Link to={`/games/${props.game._id}/${GameUrlTabNames.Gallery}`}>
        <Badge variant={"outline"} px={2} py={1} colorScheme={GameCompletionService.galleryCompleted(props.game)}>
          Gallery
        </Badge>
      </Link>
      <Link to={`/games/${props.game._id}/${GameUrlTabNames.Genres}`}>
        <Badge variant={"outline"} px={2} py={1} colorScheme={GameCompletionService.genresCompleted(props.game)}>
          Genres
        </Badge>
      </Link>
    </Flex>
  );
};
