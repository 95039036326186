import { VStack, Link, Text, StyleProps, Badge, Tooltip } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { useLocation, matchPath } from "react-router";
import { Logo } from "../../themes/icons";

export const CategoryMenu = ({ ...props }) => {
  const { pathname } = useLocation();

  const isGamesPath = matchPath("/games/*", pathname);
  const isPlatformsPath = matchPath("/platforms/*", pathname);
  const isStoresPath = matchPath("/stores/*", pathname);
  const isGenresPath = matchPath("/genres/*", pathname);

  const baseLinkProps: StyleProps = {
    w: "100%",
    py: "1",
    px: "3",
    borderRadius: "md",
  };

  const isActiveProps = (isActive: boolean): StyleProps =>
    isActive
      ? {
          bgColor: "blue.100",
        }
      : {};

  return (
    <VStack data-page-name={"CategoryMenu"} align={"start"} p="2" pos={"sticky"} zIndex={1} top={"0"}>
      <Link href="/">
        <Logo w={250} h={45} _light={{ path: { fill: "blue.300" } }} />
      </Link>
      {process.env.REACT_APP_GIT_SHA && (
        <Link
          href={`https://github.com/JustEugen/crosswatch/tree/${process.env.REACT_APP_GIT_SHA}`}
          target="_blank"
          {...baseLinkProps}
          pos="absolute"
          top={0}
          right="7px"
          w={"auto"}
          display={"block"}
          boxSizing="content-box"
          style={{ margin: 0, padding: 0, fontSize: 0 }}
        >
          <Tooltip hasArrow label="Current commit">
            <Badge colorScheme="blue" textTransform="lowercase" boxSizing="content-box">
              {process.env.REACT_APP_GIT_SHA.slice(0, 7)}
            </Badge>
          </Tooltip>
        </Link>
      )}
      <Link as={RouterLink} to={"games"} {...baseLinkProps} {...isActiveProps(!!isGamesPath)}>
        <Text fontSize="xl">Games</Text>
      </Link>
      <Link as={RouterLink} to={"platforms"} {...baseLinkProps} {...isActiveProps(!!isPlatformsPath)}>
        <Text fontSize="xl">Platforms</Text>
      </Link>
      <Link as={RouterLink} to={"stores"} {...baseLinkProps} {...isActiveProps(!!isStoresPath)}>
        <Text fontSize="xl">Stores</Text>
      </Link>
      <Link as={RouterLink} to={"genres"} {...baseLinkProps} {...isActiveProps(!!isGenresPath)}>
        <Text fontSize="xl">Genres</Text>
      </Link>
    </VStack>
  );
};
