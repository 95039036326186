import { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

type SaveData = {
  name: string;
};

type RefController = {
  open: () => void;
  clear: () => void;
  close: (options?: { clear?: boolean }) => void;
  switchLoading: (next: boolean) => void;
};

type Props = {
  onSave: (data: SaveData) => void;
};

export const CreateGenreModal = forwardRef((props: Props, ref: ForwardedRef<RefController>) => {
  const modalState = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open: () => {
      modalState.onOpen();
    },
    clear,
    close: (options) => {
      if (options?.clear) {
      }
    },
    switchLoading: (next) => setIsLoading(next),
  }));

  const [nameInputError, setNameInputError] = useState<null | string>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  const clear = () => {
    if (!nameInputRef.current) {
      return;
    }

    setNameInputError(null);
    nameInputRef.current.value = "";
  };

  const handleClose = () => {
    modalState.onClose();
    clear();
  };

  const handleSave = () => {
    if (!nameInputRef.current) {
      return;
    }

    const name = nameInputRef.current.value.trim();

    if (name.length === 0) {
      setNameInputError("Name cannot be empty");
      return;
    }

    props.onSave({
      name,
    });
  };

  return (
    <Modal isOpen={modalState.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create genre</ModalHeader>
        <ModalBody>
          <FormControl isInvalid={typeof nameInputError === "string"}>
            <FormLabel>Name</FormLabel>
            <Input ref={nameInputRef} type="text" onChange={() => setNameInputError(null)} />
            <FormErrorMessage>{nameInputError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant={"outline"}
              colorScheme={"green"}
              onClick={handleSave}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export type CreateGenreModal = {
  Props: Props;
  RefController: RefController;
};
